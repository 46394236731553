import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import { ElLoading} from 'element-plus'
import ElementPlus from 'element-plus'

import 'element-plus/dist/index.css'
import './assets/base.css'

const app = createApp(App)
app.config.globalProperties.imageList = [
  {
    id: 1,
    thumb: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/images/main/thumb1.png',
    text: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/images/main/text1.png',
    local: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/images/main/local_text1.png',
    image: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/images/main/pic1.png',
    imagep: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/images/main/pic1p.png',
    audio: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/mp3/00.mp3',
  },
  {
    id: 2,
    thumb: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/images/main/thumb2.png',
    text: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/images/main/text2.png',
    local: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/images/main/local_text2.png',
    image: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/images/main/pic2.png',
    imagep: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/images/main/pic2p.png',
    audio: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/mp3/01.mp3',
  },
  { id: 3 },
  { id: 4 },
  { id: 5 },
  { id: 6 },
  { id: 7 },
  { id: 8 },
  { id: 9 },
  { id: 10 },
  {
    id: 11,
    thumb: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/images/main/thumb3.png',
    text: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/images/main/text3.png',
    local: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/images/main/local_text3.png',
    image: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/images/main/pic3.png',
    imagep: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/images/main/pic3p.png',
    audio: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/mp3/02.mp3',
  },
]

app.config.globalProperties.modelData = [
  {
    key: 0,
    mp3: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/mp3/00.mp3',
    models: [
      'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/threem/pixiu.ubs',
      'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/threem/03.ubs',
    ],
    models_old: [
      'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/three/00/pixiu.fbx',
      'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/three/03/03.fbx',
    ],
    light: [1.3, 1.0],
    icons: [require('./assets/images/three/pic00.png'), require('./assets/images/three/pic03.png')],
    icons1: [require('./assets/images/three/pic00p.png'), require('./assets/images/three/pic03p.png')],
    top: [require('./assets/images/three/p1.png'), require('./assets/images/three/p2.png')],
    right: require('./assets/images/three/text0.png'),
    image: require('./assets/images/three/pic03.png'),
    paths: [
      {
        defaultIndex: 0,
        defaultLong: 4.728,
        objs: [
          {
            url: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/pano/pano_xiaojing/20.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 5.4718,
                latitude: -0.0034,
                target: 1,
                title: '左前方',
              },
              {
                id: 'marker02',
                longitude: 3.338,
                latitude: -0.0744,
                target: 6,
                title: '右前方',
              },
            ],
          },
          {
            url: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/pano/pano_xiaojing/21.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 5.4468,
                latitude: -0.09,
                target: 2,
                title: '左侧方',
              },
              {
                id: 'marker02',
                longitude: 3.7127,
                latitude: -0.36,
                target: 0,
                title: '正前方',
              },
            ],
          },
          {
            url: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/pano/pano_xiaojing/22.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 5.4311,
                latitude: -0.06,
                target: 3,
                title: '左后方',
              },
              {
                id: 'marker02',
                longitude: 2.08,
                latitude: -0.0424,
                target: 1,
                title: '左前方',
              },
            ],
          },
          {
            url: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/pano/pano_xiaojing/23.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 3.7031,
                latitude: 0.0936,
                target: 4,
                title: '右后方',
              },
              {
                id: 'marker02',
                longitude: 1.9928,
                latitude: 0.07,
                target: 2,
                title: '左侧方',
              },
            ],
          },
          {
            url: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/pano/pano_xiaojing/24.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 3.128,
                latitude: -0.035,
                target: 5,
                title: '右侧方',
              },
              {
                id: 'marker02',
                longitude: 1.3524,
                latitude: -0.033,
                target: 3,
                title: '左后方',
              },
            ],
          },
          {
            url: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/pano/pano_xiaojing/25.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 4.081,
                latitude: -0.2181,
                target: 6,
                title: '右前方',
              },
              {
                id: 'marker02',
                longitude: 0.958,
                latitude: -0.0539,
                target: 4,
                title: '右后方',
              },
            ],
          },
          {
            url: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/pano/pano_xiaojing/26.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 1.41,
                latitude: -0.07,
                target: 0,
                title: '正前方',
              },
              {
                id: 'marker02',
                longitude: 5.884,
                latitude: -0.049,
                target: 5,
                title: '右侧方',
              },
            ],
          },
        ],
      },
      {
        defaultIndex: 1,
        defaultLong: 4.728,
        objs: [
          {
            url: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/pano/pano_xiaojing/sd001.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 3.7299,
                latitude: 0,
                target: 1,
                title: '正前方',
              },
              {
                id: 'marker02',
                longitude: 5.7374,
                latitude: 0,
                target: 7,
                title: '左侧方',
              },
            ],
          },
          {
            url: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/pano/pano_xiaojing/sd002.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 3.4307,
                latitude: 0,
                target: 2,
                title: '右前方',
              },
              {
                id: 'marker02',
                longitude: 5.721,
                latitude: 0,
                target: 0,
                title: '左前方',
              },
            ],
          },
          {
            url: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/pano/pano_xiaojing/sd003.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 3.4307,
                latitude: 0,
                target: 3,
                title: '右侧方',
              },
              {
                id: 'marker02',
                longitude: 5.721,
                latitude: 0,
                target: 1,
                title: '正前方',
              },
            ],
          },
          {
            url: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/pano/pano_xiaojing/sd004.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 3.4307,
                latitude: 0,
                target: 4,
                title: '右后方',
              },
              {
                id: 'marker02',
                longitude: 5.721,
                latitude: 0,
                target: 2,
                title: '右前方',
              },
            ],
          },
          {
            url: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/pano/pano_xiaojing/sd005.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 3.4307,
                latitude: 0,
                target: 5,
                title: '正后方',
              },
              {
                id: 'marker02',
                longitude: 5.721,
                latitude: 0,
                target: 3,
                title: '右侧方',
              },
            ],
          },
          {
            url: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/pano/pano_xiaojing/sd006.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 3.4307,
                latitude: 0,
                target: 6,
                title: '左后方',
              },
              {
                id: 'marker02',
                longitude: 5.721,
                latitude: 0,
                target: 4,
                title: '右后方',
              },
            ],
          },
          {
            url: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/pano/pano_xiaojing/sd007.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 3.4307,
                latitude: 0,
                target: 7,
                title: '左侧方',
              },
              {
                id: 'marker02',
                longitude: 5.721,
                latitude: 0,
                target: 5,
                title: '正后方',
              },
            ],
          },
          {
            url: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/pano/pano_xiaojing/sd008.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 3.7315,
                latitude: 0,
                target: 0,
                title: '左前方',
              },
              {
                id: 'marker02',
                longitude: 5.7315,
                latitude: 0,
                target: 6,
                title: '左后方',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    key: 1,
    mp3: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/mp3/01.mp3',
    models: [
      'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/threem/01.ubs',
      'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/threem/02.ubs',
    ],
    models_old: [
      'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/three/01/01.fbx',
      'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/three/02/02.fbx',
    ],
    light: [1.3, 1.0],
    icons: [require('./assets/images/three/pic01.png'), require('./assets/images/three/pic02.png')],
    icons1: [require('./assets/images/three/pic01p.png'), require('./assets/images/three/pic02p.png')],
    top: [require('./assets/images/three/p3.png'), require('./assets/images/three/p4.png')],
    right: require('./assets/images/three/text1.png'),
    image: require('./assets/images/three/pic01.png'),

    paths: [
      {
        defaultIndex: 4,
        defaultLong: 4.7392,
        objs: [
          {
            url: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/pano/pano_yongning/16.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 0.45544,
                latitude: 0.05,
                target: 6,
                title: '右侧方',
              },
              {
                id: 'marker02',
                longitude: 4.9062092,
                latitude: 0.09026,
                target: 1,
                title: '左后方',
              },
            ],
          },
          {
            url: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/pano/pano_yongning/17.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 5.895894,
                latitude: -0.031,
                target: 0,
                title: '正后方',
              },
              {
                id: 'marker02',
                longitude: 4.3600759,
                latitude: -0.1813,
                target: 2,
                title: '左侧方',
              },
            ],
          },
          {
            url: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/pano/pano_yongning/18.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 0.6201559,
                latitude: 0.007259,
                target: 1,
                title: '左后方',
              },
              {
                id: 'marker02',
                longitude: 3.671129,
                latitude: -0.17004,
                target: 3,
                title: '左前方',
              },
            ],
          },
          {
            url: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/pano/pano_yongning/19.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 5.30919873,
                latitude: -0.0478,
                target: 2,
                title: '左侧方',
              },
              {
                id: 'marker02',
                longitude: 3.63374,
                latitude: -0.129415,
                target: 4,
                title: '正前方',
              },
            ],
          },
          {
            url: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/pano/pano_yongning/20.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 0.13243,
                latitude: -0.0901,
                target: 3,
                title: '左前方',
              },
              {
                id: 'marker02',
                longitude: 3.448587,
                latitude: 0.041015,
                target: 5,
                title: '右前方',
              },
            ],
          },
          {
            url: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/pano/pano_yongning/21.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 5.732966946,
                latitude: -0.341,
                target: 4,
                title: '正前方',
              },
              {
                id: 'marker02',
                longitude: 4.11970391,
                latitude: 0.0431,
                target: 6,
                title: '右侧方',
              },
            ],
          },
          {
            url: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/pano/pano_yongning/22.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 1.099801,
                latitude: -0.26038,
                target: 5,
                title: '右前方',
              },
              {
                id: 'marker02',
                longitude: 4.4595855,
                latitude: 0.14026,
                target: 0,
                title: '正后方',
              },
            ],
          },
        ],
      },
      {
        defaultIndex: 4,
        defaultLong: 0.1048,
        objs: [
          {
            url: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/pano/pano_yongning/00.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 1.24,
                latitude: -0.1138,
                target: 7,
                title: '右后方',
              },
              {
                id: 'marker02',
                longitude: 5.5748,
                latitude: -0.0539,
                target: 1,
                title: '左后方',
              },
            ],
          },
          {
            url: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/pano/pano_yongning/01.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 1.1618,
                latitude: -0.2707,
                target: 0,
                title: '正后方',
              },
              {
                id: 'marker02',
                longitude: 5.26,
                latitude: -0.326,
                target: 2,
                title: '左侧方',
              },
            ],
          },
          {
            url: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/pano/pano_yongning/02.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 1.6652,
                latitude: -0.3776,
                target: 1,
                title: '左后方',
              },
              {
                id: 'marker02',
                longitude: 5.053,
                latitude: -0.3828,
                target: 3,
                title: '左前方',
              },
            ],
          },
          {
            url: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/pano/pano_yongning/03.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 6.1587,
                latitude: -0.2858,
                target: 2,
                title: '左侧方',
              },
              {
                id: 'marker02',
                longitude: 4.8594,
                latitude: -0.2858,
                target: 4,
                title: '正前方',
              },
            ],
          },
          {
            url: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/pano/pano_yongning/04.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 1.3265,
                latitude: -0.107,
                target: 3,
                title: '左前方',
              },
              {
                id: 'marker02',
                longitude: 5.1753,
                latitude: -0.2511,
                target: 5,
                title: '右前方',
              },
            ],
          },
          {
            url: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/pano/pano_yongning/05.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 4.1351,
                latitude: -0.4267,
                target: 6,
                title: '右侧方',
              },
              {
                id: 'marker02',
                longitude: 5.721,
                latitude: -0.1666,
                target: 4,
                title: '正前方',
              },
            ],
          },
          {
            url: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/pano/pano_yongning/06.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 4.9933,
                latitude: -0.2132,
                target: 7,
                title: '右后方',
              },
              {
                id: 'marker02',
                longitude: 1.87,
                latitude: -0.475,
                target: 5,
                title: '右前方',
              },
            ],
          },
          {
            url: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/pano/pano_yongning/07.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 0.9255,
                latitude: -0.389,
                target: 6,
                title: '右侧方',
              },
              {
                id: 'marker02',
                longitude: 5.45,
                latitude: -0.286,
                target: 0,
                title: '正后方',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    key: 2,
  },
  { key: 3 },
  {
    key: 4,
    paths: [
      {
        defaultIndex: 1,
        defaultLong: 1.1000124524634879,
        defaultLat: 0.0034790428661937156,
        objs: [
          {
            url: 'https://baisui-weichat.oss-cn-shenzhen.aliyuncs.com/nanjinshike/immersion/pano/4/0.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 2.5595980085254637,
                latitude: -0.4710326962532063,
                target: 1,
              },
              {
                id: 'marker02',
                longitude: 2.082162303299639,
                latitude: -0.2666422128610848,
                target: 2,
              },
              {
                id: 'marker03',
                longitude: 1.5798842796783261,
                latitude: -0.1020246427375675,
                target: 3,
              },
              {
                id: 'marker04',
                longitude: 0.7199554442629688,
                latitude: -0.1619399228688776,
                target: 4,
              },
              {
                id: 'marker05',
                longitude: 0.5112517753568123,
                latitude: -0.13946492498253815,
                target: 5,
              },
              {
                id: 'marker06',
                longitude: 6.282108573959529,
                latitude: -0.4237780645033049,
                target: 6,
              },
            ],
          },
          // 1正前方
          {
            url: 'https://baisui-weichat.oss-cn-shenzhen.aliyuncs.com/nanjinshike/immersion/pano/4/2.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 5.946711917920492,
                latitude: -0.37828809679025843,
                target: 0,
              },
              {
                id: 'marker02',
                longitude: 0.3403319584255004,
                latitude: -0.3247664597606936,
                target: 6,
              },
              {
                id: 'marker03',
                longitude: 2.519018272443499,
                latitude: -0.30954322238704557,
                target: 2,
              },
              {
                id: 'marker04',
                longitude: 1.8858491578888066,
                latitude: -0.2447439892746155,
                target: 3,
              },
              {
                id: 'marker05',
                longitude: 1.409558148302787,
                latitude: -0.10553856449159649,
                target: 4,
              },
              {
                id: 'marker06',
                longitude: 0.8287707293541174,
                latitude: -0.07130812167326961,
                target: 5,
              },
            ],
          },
          // 2 正右侧
          {
            url: 'https://baisui-weichat.oss-cn-shenzhen.aliyuncs.com/nanjinshike/immersion/pano/4/6.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 1.9402818000529463,
                latitude: -0.41665482195111525,
                target: 3,
              },
              {
                id: 'marker02',
                longitude: 1.4535429525584784,
                latitude: -0.19330104700796502,
                target: 4,
              },
              {
                id: 'marker03',
                longitude: 0.9868544440281506,
                latitude: -0.02060113075240788,
                target: 5,
              },
              {
                id: 'marker04',
                longitude: 0.45498000856604803,
                latitude: -0.1341196723012552,
                target: 6,
              },
              {
                id: 'marker05',
                longitude: 6.083393979180402,
                latitude: -0.23696719381849007,
                target: 0,
              },
            ],
          },

          // 3 右侧方
          {
            url: 'https://baisui-weichat.oss-cn-shenzhen.aliyuncs.com/nanjinshike/immersion/pano/4/8.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 5.615444734131812,
                latitude: -0.4570466092940113,
                target: 2,
              },
              {
                id: 'marker02',
                longitude: 0.0909310131319509,
                latitude: -0.29752663962978065,
                target: 1,
              },
              {
                id: 'marker03',
                longitude: 0.4279089637881248,
                latitude: -0.18661055048765451,
                target: 0,
              },
              {
                id: 'marker04',
                longitude: 2.2243119510982163,
                latitude: -0.2262844531929189,
                target: 4,
              },
              {
                id: 'marker05',
                longitude: 1.7989625161396994,
                latitude: -0.12285622163959031,
                target: 5,
              },
              {
                id: 'marker06',
                longitude: 1.0979211943507343,
                latitude: -0.05185057197630161,
                target: 6,
              },
            ],
          },
          // 4 后左侧
          {
            url: 'https://baisui-weichat.oss-cn-shenzhen.aliyuncs.com/nanjinshike/immersion/pano/4/10.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 2.079766006357048,
                latitude: -0.28025464112181475,
                target: 5,
              },
              {
                id: 'marker02',
                longitude: 0.9109524710985003,
                latitude: -0.11731063120628216,
                target: 6,
              },
              {
                id: 'marker03',
                longitude: 0.5629696349443167,
                latitude: -0.10854781239521905,
                target: 0,
              },
              {
                id: 'marker04',
                longitude: 0.2542753386206564,
                latitude: -0.14588254540244505,
                target: 1,
              },
              {
                id: 'marker05',
                longitude: 6.0682206963712115,
                latitude: -0.18919390301011374,
                target: 2,
              },
              {
                id: 'marker06',
                longitude: 5.88915871840726,
                latitude: -0.36759085586592244,
                target: 3,
              },
            ],
          },
          // 5后方
          {
            url: 'https://baisui-weichat.oss-cn-shenzhen.aliyuncs.com/nanjinshike/immersion/pano/4/14.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 1.8921858720234936,
                latitude: -0.32509385514647726,
                target: 6,
              },
              {
                id: 'marker02',
                longitude: 1.7274608571196715,
                latitude: -0.16037688859385568,
                target: 0,
              },
              {
                id: 'marker03',
                longitude: 1.2316110407071714,
                latitude: -0.11032172367696713,
                target: 1,
              },
              {
                id: 'marker04',
                longitude: 0.6791904499507131,
                latitude: -0.06425133767153901,
                target: 2,
              },
              {
                id: 'marker05',
                longitude: 0.34372518701653604,
                latitude: -0.15615789792009283,
                target: 3,
              },
              {
                id: 'marker06',
                longitude: 5.964293140525644,
                latitude: -0.3601756007452377,
                target: 4,
              },
            ],
          },
          // 6左侧
          {
            url: 'https://baisui-weichat.oss-cn-shenzhen.aliyuncs.com/nanjinshike/immersion/pano/4/18.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 2.7706221381914586,
                latitude: -0.4412988095343504,
                target: 0,
              },
              {
                id: 'marker02',
                longitude: 2.2916026736667536,
                latitude: -0.27497474360190877,
                target: 1,
              },
              {
                id: 'marker03',
                longitude: 1.79642514088738,
                latitude: -0.1952928463341692,
                target: 2,
              },
              {
                id: 'marker04',
                longitude: 0.5284404246312331,
                latitude: -0.07593723020187526,
                target: 4,
              },
              {
                id: 'marker05',
                longitude: 0.13709014850855716,
                latitude: -0.27884229361768686,
                target: 5,
              },
              {
                id: 'marker06',
                longitude: 1.110885286130651,
                latitude: -0.05261395512806666,
                target: 3,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    key: 5,
    paths: [
      {
        defaultIndex: 7,
        defaultLong: 1.1000124524634879,
        defaultLat: 0.0034790428661937156,
        objs: [
          {
            url: 'https://baisui-weichat.oss-cn-shenzhen.aliyuncs.com/nanjinshike/immersion/pano/5/0.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 1.6683222619197102,
                latitude: -0.5004365003507067,
                target: 1,
              },
              {
                id: 'marker02',
                longitude: 1.1356350670767181,
                latitude: -0.2672426265683727,
                target: 2,
              },
              {
                id: 'marker03',
                longitude: 0.6501822054824279,
                latitude: -0.15208789367815023,
                target: 3,
              },
              {
                id: 'marker04',
                longitude: 0.13936953003057675,
                latitude: -0.03765188566680244,
                target: 4,
              },
              {
                id: 'marker05',
                longitude: 6.095508442973229,
                latitude: -0.01268105235115402,
                target: 5,
              },
              {
                id: 'marker06',
                longitude: 5.804289611076871,
                latitude: -0.10251872457529565,
                target: 6,
              },
              {
                id: 'marker07',
                longitude: 5.439362143145166,
                latitude: -0.4914296224860153,
                target: 7,
              },
            ],
          },
          // 1正前方
          {
            url: 'https://baisui-weichat.oss-cn-shenzhen.aliyuncs.com/nanjinshike/immersion/pano/5/2.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 1.770031253194688,
                latitude: -0.38772649257523284,
                target: 2,
              },
              {
                id: 'marker02',
                longitude: 1.027961758488955,
                latitude: -0.29397516488747977,
                target: 3,
              },
              {
                id: 'marker03',
                longitude: 0.49468496798248174,
                latitude: -0.08896670538440854,
                target: 4,
              },
              {
                id: 'marker04',
                longitude: 0.27212554530864946,
                latitude: -0.09678875668228759,
                target: 5,
              },
              {
                id: 'marker05',
                longitude: 6.097010583109867,
                latitude: -0.11770413550485204,
                target: 6,
              },
              {
                id: 'marker06',
                longitude: 5.605410348444957,
                latitude: -0.3049610568132226,
                target: 7,
              },
              {
                id: 'marker07',
                longitude: 4.856938391592045,
                latitude: -0.4620082126336147,
                target: 0,
              },
            ],
          },
          // 2 正右侧
          {
            url: 'https://baisui-weichat.oss-cn-shenzhen.aliyuncs.com/nanjinshike/immersion/pano/5/4.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 1.4846018948461155,
                latitude: -0.4090634661128263,
                target: 3,
              },
              {
                id: 'marker02',
                longitude: 1.3124769649225407,
                latitude: -0.0842278659735256,
                target: 4,
              },
              {
                id: 'marker03',
                longitude: 0.9874580759604865,
                latitude: -0.07835422613184995,
                target: 5,
              },
              {
                id: 'marker04',
                longitude: 0.4701629731194635,
                latitude: -0.09079102749066448,
                target: 6,
              },
              {
                id: 'marker05',
                longitude: 0.17346749847412274,
                latitude: -0.1891147226858121,
                target: 7,
              },
              {
                id: 'marker06',
                longitude: 5.9505897650499735,
                latitude: -0.2638765975724038,
                target: 0,
              },
              {
                id: 'marker07',
                longitude: 5.417112914254855,
                latitude: -0.45267011425444625,
                target: 1,
              },
            ],
          },

          // 3 右侧方
          {
            url: 'https://baisui-weichat.oss-cn-shenzhen.aliyuncs.com/nanjinshike/immersion/pano/5/6.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 1.3683533716004994,
                latitude: -0.14629063242367768,
                target: 4,
              },
              {
                id: 'marker02',
                longitude: 0.4944483717005082,
                latitude: -0.009126692579768836,
                target: 6,
              },
              {
                id: 'marker03',
                longitude: 0.07737813014732366,
                latitude: -0.0727434199803969,
                target: 7,
              },
              {
                id: 'marker04',
                longitude: 5.80542040247758,
                latitude: -0.15203245104033591,
                target: 0,
              },
              {
                id: 'marker05',
                longitude: 5.304096436906608,
                latitude: -0.30241103443711737,
                target: 1,
              },
            ],
          },
          // 4 后左侧
          {
            url: 'https://baisui-weichat.oss-cn-shenzhen.aliyuncs.com/nanjinshike/immersion/pano/5/8.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 1.250757848171585,
                latitude: -0.1809977545605579,
                target: 6,
              },
              {
                id: 'marker02',
                longitude: 0.3303123935882397,
                latitude: -0.08163820157965151,
                target: 7,
              },
              {
                id: 'marker03',
                longitude: 6.064993792605167,
                latitude: -0.1891181621450213,
                target: 1,
              },
              {
                id: 'marker04',
                longitude: 5.691475251345726,
                latitude: -0.20082936809634622,
                target: 2,
              },
              {
                id: 'marker05',
                longitude: 5.520619191058469,
                latitude: -0.4088005562933035,
                target: 3,
              },
            ],
          },
          // 5后方
          {
            url: 'https://baisui-weichat.oss-cn-shenzhen.aliyuncs.com/nanjinshike/immersion/pano/5/12.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 1.5688393584805682,
                latitude: -0.26702721043419664,
                target: 6,
              },
              {
                id: 'marker02',
                longitude: 0.6395856987622346,
                latitude: -0.1647200139894236,
                target: 7,
              },
              {
                id: 'marker03',
                longitude: 0.34661001475039216,
                latitude: -0.15618328425201522,
                target: 0,
              },
              {
                id: 'marker04',
                longitude: 6.242897801461251,
                latitude: -0.11989695999368832,
                target: 1,
              },
              {
                id: 'marker05',
                longitude: 5.948918447067108,
                latitude: -0.1856584214440986,
                target: 2,
              },
              {
                id: 'marker06',
                longitude: 5.605861128989334,
                latitude: -0.36577847882827874,
                target: 3,
              },
            ],
          },
          // 6后右边侧
          {
            url: 'https://baisui-weichat.oss-cn-shenzhen.aliyuncs.com/nanjinshike/immersion/pano/5/16.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 1.4672083573935761,
                latitude: -0.36488401920090685,
                target: 7,
              },
              {
                id: 'marker02',
                longitude: 1.0288384841770084,
                latitude: -0.11320462474233106,
                target: 0,
              },
              {
                id: 'marker03',
                longitude: 0.6811353169057442,
                latitude: -0.0701831615988433,
                target: 1,
              },
              {
                id: 'marker04',
                longitude: 0.26672903385961694,
                latitude: -0.06429879324020504,
                target: 2,
              },
              {
                id: 'marker05',
                longitude: 6.156848011888468,
                latitude: -0.13337421430974006,
                target: 3,
              },
              {
                id: 'marker06',
                longitude: 5.567587834786443,
                latitude: -0.14056201363998433,
                target: 4,
              },
            ],
          },
          // 7 左侧
          {
            url: 'https://baisui-weichat.oss-cn-shenzhen.aliyuncs.com/nanjinshike/immersion/pano/5/18.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 1.6707518103021637,
                latitude: -0.26336978673145506,
                target: 0,
              },
              {
                id: 'marker02',
                longitude: 1.1536008777516036,
                latitude: -0.2201073056435192,
                target: 1,
              },
              {
                id: 'marker03',
                longitude: 0.6360970509366317,
                latitude: -0.13284440014752708,
                target: 2,
              },
              {
                id: 'marker04',
                longitude: 0.24198027105016293,
                latitude: -0.08978963398301087,
                target: 3,
              },
              {
                id: 'marker05',
                longitude: 5.819902190479172,
                latitude: -0.11292448888837003,
                target: 4,
              },
              {
                id: 'marker06',
                longitude: 5.482743556031108,
                latitude: -0.15279637350426523,
                target: 5,
              },
              {
                id: 'marker07',
                longitude: 4.86824349568149,
                latitude: -0.3103737617401645,
                target: 6,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    key: 6,
    light: [1.3, 1.0],
    mp3: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/mp3/02.mp3',
    icons: [require('./assets/images/three/pic04.png'), require('./assets/images/three/pic05.png')],
    icons1: [require('./assets/images/three/pic04p.png'), require('./assets/images/three/pic05p.png')],
    models: [
      // 'https://baisui-weichat.oss-cn-shenzhen.aliyuncs.com/nanjinshike/immersion/2048/xiaohong_shibei.ubs',
      // 'https://baisui-weichat.oss-cn-shenzhen.aliyuncs.com/nanjinshike/immersion/2048/xiaohong_shibei.ubs',
      "//baisui-weichat.oss-cn-shenzhen.aliyuncs.com/nanjinshike/immersion/2048/small/model.ubs",
      "//baisui-weichat.oss-cn-shenzhen.aliyuncs.com/nanjinshike/immersion/2048/small/model.ubs"
    ],
    paths: [],
  },
  { key: 7 },
  { key: 8 },
  {
    key: 9,
    paths: [
      {
        defaultIndex: 0,
        defaultLong: 5.3489804526744225,
        objs: [
          //0 进门
          {
            url: 'https://baisui-weichat.oss-cn-shenzhen.aliyuncs.com/nanjinshike/immersion/pano/9/0.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 0.4832313537438646,
                latitude: -0.20957922639175042,
                target: 1,
              },
              {
                id: 'marker02',
                longitude: 0.6294316641192499,
                latitude: -0.01967473439634171,
                target: 2,
              },
              {
                id: 'marker03',
                longitude: 0.9552991694674443,
                latitude: -0.05347431130336977,
                target: 3,
              },
              {
                id: 'marker04',
                longitude: 1.1718882541435283,
                latitude: -0.06256446591046427,
                target: 4,
              },
              {
                id: 'marker05',
                longitude: 1.3267513577858594,
                latitude: -0.17783010836946045,
                target: 5,
              },
              {
                id: 'marker06',
                longitude: 0.755445083927463,
                latitude: -0.19664893640619874,
                target: 6,
              },
              {
                id: 'marker07',
                longitude: 1.5453538012302301,
                latitude: -0.4529730925926252,
                target: 7,
              },
              {
                id: 'marker08',
                longitude: 1.7718826546851674,
                latitude: -0.20340745650055148,
                target: 8,
              },
              {
                id: 'marker09',
                longitude: 2.3181552714150406,
                latitude: -0.41943882851253034,
                target: 9,
              },
            ],
          },
          // 1 楼梯转角
          {
            url: 'https://baisui-weichat.oss-cn-shenzhen.aliyuncs.com/nanjinshike/immersion/pano/9/4.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 0.6399438198526765,
                latitude: -0.07334583942174122,
                target: 2,
              },
              {
                id: 'marker02',
                longitude: 1.0654228143017999,
                latitude: -0.015879401836220275,
                target: 3,
              },
              {
                id: 'marker03',
                longitude: 1.8274535087595107,
                latitude: -0.18439703106443783,
                target: 5,
              },
              {
                id: 'marker04',
                longitude: 2.9759048347406867,
                latitude: -0.2588049665238574,
                target: 7,
              },
              {
                id: 'marker05',
                longitude: 2.551754594911089,
                latitude: -0.19258745299203084,
                target: 8,
              },
              {
                id: 'marker06',
                longitude: 3.1940409788582476,
                latitude: -0.04183033495582622,
                target: 9,
              },
            ],
          },
          // 2 上楼梯
          {
            url: 'https://baisui-weichat.oss-cn-shenzhen.aliyuncs.com/nanjinshike/immersion/pano/9/8.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 1.6895024365382507,
                latitude: -0.39198202870359933,
                target: 3,
              },
              {
                id: 'marker02',
                longitude: 2.430835222362338,
                latitude: -0.21934495901832163,
                target: 5,
              },
              {
                id: 'marker03',
                longitude: 2.885574306464336,
                latitude: -0.46851010418155514,
                target: 6,
              },
              {
                id: 'marker04',
                longitude: 2.768994738972627,
                latitude: -0.21909930714527714,
                target: 8,
              },
              {
                id: 'marker05',
                longitude: 3.14946121990122,
                latitude: -0.21965164791570602,
                target: 7,
              },
              {
                id: 'marker06',
                longitude: 3.5759469241691,
                latitude: -0.18793221136646898,
                target: 0,
              },
            ],
          },
          // 3 柱子后面
          {
            url: 'https://baisui-weichat.oss-cn-shenzhen.aliyuncs.com/nanjinshike/immersion/pano/9/10.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 1.8168894095518797,
                latitude: -0.2610683384006869,
                target: 8,
              },
              {
                id: 'marker02',
                longitude: 2.218635388194294,
                latitude: -0.23850855329791032,
                target: 7,
              },
              {
                id: 'marker03',
                longitude: 1.2849381848845292,
                latitude: -0.3412131632789537,
                target: 5,
              },
              {
                id: 'marker04',
                longitude: 2.7845428223113355,
                latitude: -0.25148228319144583,
                target: 1,
              },
            ],
          },
          // 4 柱子最角落
          {
            url: 'https://baisui-weichat.oss-cn-shenzhen.aliyuncs.com/nanjinshike/immersion/pano/9/12.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 0.5103712418204499,
                latitude: -0.2588667755387386,
                target: 7,
              },
              {
                id: 'marker02',
                longitude: 0.031489299023329066,
                latitude: -0.3467622024846939,
                target: 8,
              },
              {
                id: 'marker03',
                longitude: 0.7889042013522404,
                latitude: -0.18354435569861627,
                target: 0,
              },
              {
                id: 'marker04',
                longitude: 1.1797593142762575,
                latitude: -0.21678222419642945,
                target: 1,
              },
              {
                id: 'marker05',
                longitude: 1.9283594752091817,
                latitude: -0.14828345497815243,
                target: 2,
              },
            ],
          },

          // 5 柱子最角落
          {
            url: 'https://baisui-weichat.oss-cn-shenzhen.aliyuncs.com/nanjinshike/immersion/pano/9/14.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 0.5103712418204499,
                latitude: -0.2588667755387386,
                target: 7,
              },
              {
                id: 'marker02',
                longitude: 0.031489299023329066,
                latitude: -0.3467622024846939,
                target: 8,
              },
              {
                id: 'marker03',
                longitude: 0.7889042013522404,
                latitude: -0.18354435569861627,
                target: 0,
              },
              {
                id: 'marker04',
                longitude: 1.4473355140421267,
                latitude: -0.16107480777555994,
                target: 1,
              },
              {
                id: 'marker05',
                longitude: 1.9283594752091817,
                latitude: -0.14828345497815243,
                target: 2,
              },
              {
                id: 'marker06',
                longitude: 2.573613188583492,
                latitude: -0.1210453723038496,
                target: 3,
              },
            ],
          },

          // 6柱子前面
          {
            url: 'https://baisui-weichat.oss-cn-shenzhen.aliyuncs.com/nanjinshike/immersion/pano/9/18.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 0.00903538337876973,
                latitude: -0.28840018877376195,
                target: 7,
              },
              {
                id: 'marker02',
                longitude: 5.772630551539753,
                latitude: -0.24281271781619007,
                target: 8,
              },
              {
                id: 'marker03',
                longitude: 0.6274919505552756,
                latitude: -0.16783937867708398,
                target: 0,
              },
              {
                id: 'marker04',
                longitude: 2.9375803134684624,
                latitude: -0.04674808540507325,
                target: 2,
              },
              {
                id: 'marker05',
                longitude: 4.006925127054689,
                latitude: -0.07008127601446246,
                target: 3,
              },
              {
                id: 'marker06',
                longitude: 4.784728752102433,
                latitude: -0.2550241160807647,
                target: 5,
              },
            ],
          }, // 7 石碑前面
          {
            url: 'https://baisui-weichat.oss-cn-shenzhen.aliyuncs.com/nanjinshike/immersion/pano/9/20.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 0.10540687418048988,
                latitude: -0.20519103554866058,
                target: 9,
              },
              {
                id: 'marker02',
                longitude: 4.786707981503451,
                latitude: -0.3229296195776892,
                target: 8,
              },
              {
                id: 'marker03',
                longitude: 1.188919001206769,
                latitude: -0.25148119729697394,
                target: 0,
              },
              {
                id: 'marker04',
                longitude: 2.497569112187479,
                latitude: -0.263745995266508,
                target: 1,
              },
              {
                id: 'marker05',
                longitude: 2.9158064747636336,
                latitude: -0.04673706451257975,
                target: 2,
              },
              {
                id: 'marker06',
                longitude: 3.1325382780690396,
                latitude: -0.20410165436621486,
                target: 6,
              },
              {
                id: 'marker07',
                longitude: 3.828388504888913,
                latitude: -0.21006362444896176,
                target: 5,
              },
            ],
          },
          // 8 最角落
          {
            url: 'https://baisui-weichat.oss-cn-shenzhen.aliyuncs.com/nanjinshike/immersion/pano/9/22.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 3.138977832374578,
                latitude: -0.206104441175462,
                target: 9,
              },
              {
                id: 'marker02',
                longitude: 3.64876437995945,
                latitude: -0.4416628896211865,
                target: 7,
              },
              {
                id: 'marker03',
                longitude: 3.5817465098023216,
                latitude: -0.13134389953796832,
                target: 0,
              },
              {
                id: 'marker04',
                longitude: 4.407834950761601,
                latitude: -0.1416237104423388,
                target: 1,
              },
              {
                id: 'marker05',
                longitude: 4.806951016051036,
                latitude: -0.05052562252209025,
                target: 2,
              },
              {
                id: 'marker06',
                longitude: 4.819813125717901,
                latitude: -0.21905950613950642,
                target: 6,
              },
              {
                id: 'marker07',
                longitude: 5.655047638498999,
                latitude: -0.2240818286944517,
                target: 5,
              },
            ],
          },
          // 9 石碑后面
          {
            url: 'https://baisui-weichat.oss-cn-shenzhen.aliyuncs.com/nanjinshike/immersion/pano/9/26.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 6.238130705261761,
                latitude: -0.2578860810469883,
                target: 0,
              },
              {
                id: 'marker02',
                longitude: 2.4944028939914404,
                latitude: -0.2752960223966845,
                target: 8,
              },
              {
                id: 'marker03',
                longitude: 1.4577911788886748,
                latitude: -0.009130061490227437,
                target: 7,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    key: 10,
    mp3: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/mp3/02.mp3',
    icons: [require('./assets/images/three/pic04.png'), require('./assets/images/three/pic05.png')],
    icons1: [require('./assets/images/three/pic04p.png'), require('./assets/images/three/pic05p.png')],
    top: [require('./assets/images/three/p5.png'), require('./assets/images/three/p6.png')],
    models: [
      'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/threem/04.ubs',
      'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/threem/05.ubs',
    ],
    light: [1.3, 1.3],
    models_old: [
      'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/three/04/04.fbx',
      'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/three/05/05.fbx',
    ],
    right: require('./assets/images/three/text2.png'),
    image: require('./assets/images/three/pic04.png'),
    paths: [
      {
        defaultIndex: 5,
        defaultLong: 4.602,
        objs: [
          {
            url: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/pano/pano_chuningling1/qlpsk001.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 0.0656,
                latitude: -0.2439,
                target: 1,
                title: '正后方',
              },
              {
                id: 'marker02',
                longitude: 2.4857,
                latitude: -0.5277,
                target: 7,
                title: '右侧方',
              },
            ],
          },
          {
            url: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/pano/pano_chuningling1/qlpsk002.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 1.3518,
                latitude: -0.39,
                target: 2,
                title: '左后方',
              },
              {
                id: 'marker02',
                longitude: 2.975,
                latitude: -0.3099,
                target: 0,
                title: '右后方',
              },
            ],
          },
          {
            url: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/pano/pano_chuningling1/qlpsk003.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 0.2612,
                latitude: -0.3021,
                target: 3,
                title: '左侧方',
              },
              {
                id: 'marker02',
                longitude: 2.72,
                latitude: -0.5233,
                target: 1,
                title: '正后方',
              },
            ],
          },
          {
            url: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/pano/pano_chuningling1/qlpsk004.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 6.156,
                latitude: -0.5541,
                target: 4,
                title: '左前方',
              },
              {
                id: 'marker02',
                longitude: 2.0621,
                latitude: -0.6195,
                target: 2,
                title: '左后方',
              },
            ],
          },
          {
            url: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/pano/pano_chuningling1/qlpsk005.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 6.2632,
                latitude: -0.516,
                target: 5,
                title: '正前方',
              },
              {
                id: 'marker02',
                longitude: 2.922,
                latitude: -0.2543,
                target: 3,
                title: '左侧方',
              },
            ],
          },
          {
            url: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/pano/pano_chuningling1/qlpsk006.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 3.4307,
                latitude: -0.201,
                target: 6,
                title: '右前方',
              },
              {
                id: 'marker02',
                longitude: 5.5355,
                latitude: -0.2261,
                target: 4,
                title: '左前方',
              },
            ],
          },
          {
            url: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/pano/pano_chuningling1/qlpsk007.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 3.4307,
                latitude: -0.5261,
                target: 5,
                title: '正前方',
              },
              {
                id: 'marker02',
                longitude: 5.721,
                latitude: -0.5837,
                target: 7,
                title: '右侧方',
              },
            ],
          },
          {
            url: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/pano/pano_chuningling1/qlpsk008.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 3.584,
                latitude: -0.419,
                target: 0,
                title: '右后方',
              },
              {
                id: 'marker02',
                longitude: 5.66,
                latitude: -0.291,
                target: 6,
                title: '右前方',
              },
            ],
          },
        ],
      },
      {
        defaultIndex: 2,
        defaultLong: 3.10312,
        objs: [
          {
            url: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/pano/pano_chuningling2/1.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 1.903724,
                latitude: -0.1583,
                target: 1,
                title: '右前方',
              },
              {
                id: 'marker02',
                longitude: 5.690821,
                latitude: -0.106696,
                target: 9,
                title: '右后方2',
              },
            ],
          },
          {
            url: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/pano/pano_chuningling2/2.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 3.716963,
                latitude: -0.18302,
                target: 2,
                title: '正前方',
              },
              {
                id: 'marker02',
                longitude: 2.0924857,
                latitude: -0.11469,
                target: 0,
                title: '右侧方',
              },
            ],
          },
          {
            url: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/pano/pano_chuningling2/3.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 4.042,
                latitude: -0.188,
                target: 3,
                title: '左前方',
              },
              {
                id: 'marker02',
                longitude: 2.3796,
                latitude: -0.2225,
                target: 1,
                title: '右前方',
              },
            ],
          },
          {
            url: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/pano/pano_chuningling2/4.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 3.16523,
                latitude: -0.13974,
                target: 4,
                title: '左侧方',
              },
              {
                id: 'marker02',
                longitude: 2.0337,
                latitude: -0.19925,
                target: 2,
                title: '正前方',
              },
            ],
          },
          {
            url: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/pano/pano_chuningling2/5.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 5.41051,
                latitude: -0.1746,
                target: 5,
                title: '左后方1',
              },
              {
                id: 'marker02',
                longitude: 1.52196,
                latitude: -0.2234,
                target: 3,
                title: '左前方',
              },
            ],
          },
          {
            url: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/pano/pano_chuningling2/6.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 4.677324,
                latitude: -0.21137,
                target: 6,
                title: '左后方2',
              },
              {
                id: 'marker02',
                longitude: 3.170716,
                latitude: -0.19255,
                target: 4,
                title: '左侧方',
              },
            ],
          },
          {
            url: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/pano/pano_chuningling2/7.jpg',
            markers: [
              {
                id: 'marker02',
                longitude: 4.996019,
                latitude: -0.34265,
                target: 7,
                title: '正后方',
              },
              {
                id: 'marker01',
                longitude: 1.69516,
                latitude: -0.2191,
                target: 5,
                title: '左后方1',
              },
            ],
          },
          {
            url: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/pano/pano_chuningling2/8.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 3.7454,
                latitude: -0.19058,
                target: 8,
                title: '右后方1',
              },
              {
                id: 'marker02',
                longitude: 1.543022,
                latitude: -0.15,
                target: 6,
                title: '左后方2',
              },
            ],
          },
          {
            url: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/pano/pano_chuningling2/9.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 4.251399,
                latitude: -0.340653,
                target: 9,
                title: '右后方2',
              },
              {
                id: 'marker02',
                longitude: 1.86316836,
                latitude: -0.16818,
                target: 7,
                title: '正后方',
              },
            ],
          },
          {
            url: 'https://iablog.oss-cn-qingdao.aliyuncs.com/pixiu360/pano/pano_chuningling2/10.jpg',
            markers: [
              {
                id: 'marker01',
                longitude: 5.515769,
                latitude: -0.23765,
                target: 0,
                title: '右侧方',
              },
              {
                id: 'marker02',
                longitude: 2.1198415,
                latitude: -0.1619,
                target: 8,
                title: '右后方1',
              },
            ],
          },
        ],
      },
    ],
  },
]

app
  .use(store)
  .use(router)
  .use(ElementPlus)
  // .directive('loading', ElLoading.directive)
  .mount('#app')
