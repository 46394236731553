<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
  created() {
    const { type } = this.$route.query
    if (type) {
      if (![0, 1, 10].includes(Number(type))) {
        this.$store.commit('updateSubActive', 0);
      }
      this.$store.commit('updateActive', type);
      console.log('cehis', this.$route)
    }
  }
}
</script>

<style></style>
