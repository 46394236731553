import { createRouter, createWebHistory } from 'vue-router'
const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import(/* webpackChunkName: 'Index' */ '../pages/Index.vue'),
  },
  {
    path: '/view',
    name: 'View',
    component: () => import(/* webpackChunkName: 'View'*/ '../pages/View.vue'),
  },
  {
    path: '/three',
    name: 'Three',
    component: () => import(/* webpackChunkName: 'Three'*/ '../pages/Three.vue'),
  },
  {
    path: '/pano',
    name: 'Pano',
    component: () => import(/* webpackChunkName: 'Pano'*/ '../pages/Pano.vue'),
  },
  {
    path: '/share',
    name: 'Share',
    component: () => import(/* webpackChunkName: 'Share'*/ '../pages/Share.vue'),
  },
]

const router = createRouter({
  mode: 'history',
  history: createWebHistory('./'),
  routes,
})

router.afterEach(() => {
  console.log('afterEach')
  let bodySrcollTop = document.body.scrollTop
  if (bodySrcollTop !== 0) {
    document.body.scrollTop = 0
    return
  }
  let docSrcollTop = document.documentElement.scrollTop
  if (docSrcollTop !== 0) {
    document.documentElement.scrollTop = 0
  }
})

export default router
