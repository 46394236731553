import { createStore } from 'vuex'
export default createStore({
  state: {
    active: localStorage.getItem('active') || 0,
    subActive: 0,
  },
  mutations: {
    updateActive(state, active) {
      state.active = active
      localStorage.setItem('active', active)
    },
    updateSubActive(state, subActive) {
      state.subActive = subActive
      localStorage.setItem('subActive', subActive)
    },
  },
})
